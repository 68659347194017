import { useEffect, useState } from 'react';
import { useDisclaimerContext } from '../../../../lib/contexts';
import { CustomModalContent } from '../../../CustomComponents';

function DesignCheckbox({
  lenderList,
  selectedList,
  onSelect,
  onSubmit,
  phone,
  firstOneToOneSubmit,
  selectAll,
  setIsLoading,
  setIsLoadingNoText,
}) {
  const [error, setError] = useState(false);
  const [lenderOptions, setLenderOptions] = useState([]);

  const { disclaimerContext } = useDisclaimerContext();
  const { partnerList, termsOfUse, privacyPolicy, arbitrate } =
    disclaimerContext;

  const [isOpen, setIsOpen] = useState({
    partnerList: false,
    termsOfUse: false,
    privacyPolicy: false,
    arbitrate: false,
  });

  const openModal = (key) => setIsOpen({ ...isOpen, [key]: true });
  const closeModal = (key) => setIsOpen({ ...isOpen, [key]: false });

  const click = (event) => {
    if (event.target?.id === 'ppTag') {
      setIsOpen({
        partnerList: false,
        termsOfUse: false,
        privacyPolicy: true,
        arbitrate: false,
      });
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.target?.id === 'touTag') {
      setIsOpen({
        partnerList: false,
        termsOfUse: true,
        privacyPolicy: false,
        arbitrate: false,
      });
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const submitList = () => {
    if (!selectedList.length) {
      setError(true);
    } else {
      setError(false);
      onSubmit();
    }
  };

  const initLenders = () => {
    const list = lenderList
      .filter((item) => item.name !== 'modernizeintegration')
      .map((item) => ({ value: item.name, label: item.name }));

    setLenderOptions(list);
    selectAll(list.map((item) => item.value));
  };

  useEffect(() => {
    if (lenderList[0].name === 'modernizeintegration') {
      const modernizeLenderListUrl = lenderList[0].data;

      fetch(modernizeLenderListUrl)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong');
        })
        .then((responseJson) => {
          const modernizeLenderList = responseJson.consentListings.map(
            (item) => ({
              value: `modernizeintegration_${item.consentKey}`,
              label: item.displayName,
            })
          );

          if (modernizeLenderList.length < 5 && lenderList.length > 1) {
            const additionalList = lenderList.slice(1, 6).map((item) => ({
              value: item.name,
              label: item.name,
            }));
            modernizeLenderList.push(...additionalList);
          }

          setLenderOptions(modernizeLenderList);
          selectAll(modernizeLenderList.map((item) => item.value));

          setIsLoading(false);
          setIsLoadingNoText(false);
        })
        .catch((error) => {
          initLenders();
          setIsLoading(false);
          setIsLoadingNoText(false);
        });
    } else {
      setIsLoading(false);
      setIsLoadingNoText(false);
      initLenders();
    }
  }, [lenderList]);

  return (
    <div className="form-step3">
      <CustomModalContent
        title="Partner List"
        isOpen={isOpen.partnerList}
        onClose={() => closeModal('partnerList')}
        content={partnerList.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Terms Of Use"
        isOpen={isOpen.termsOfUse}
        onClose={() => closeModal('termsOfUse')}
        content={termsOfUse.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Privacy Policy"
        isOpen={isOpen.privacyPolicy}
        onClose={() => closeModal('privacyPolicy')}
        content={privacyPolicy.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Arbitrate"
        isOpen={isOpen.arbitrate}
        onClose={() => closeModal('arbitrate')}
        content={arbitrate.data?.html}
        clickEvent={click}
        isHtml
      />
      {lenderOptions.length > 1 &&
      <form data-tf-element-role="offer">
        <div className="form-step__label">You're Almost There!</div>
        <div className="form-step form-multiple-checkbox">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {!firstOneToOneSubmit &&
            <div style={{ fontSize: '13px', marginTop: '5px', color: 'black' }}>
              We’re sorry, but the partners you selected couldn't assist with your request. Here are some additional partners that are ready to assist you!
            </div>
          }
          <label htmlFor="leadid_tcpa_disclosure_t">
            <div style={{ fontSize: '13px', marginTop: '5px', color: 'black' }}>
              <input type="hidden" id="leadid_tcpa_disclosure_t" />
              By clicking "<span data-tf-element-role="submit-text">Get Your Matches</span>" I am electronically signing and expressly consenting that the company or companies selected below, may contact me for marketing purposes at <span
              data-tf-element-role="consent-grantor-phone">{phone}</span>, including with an automated telephone dialing system, pre-recorded or artificial voice, and/or SMS/MMS, and consenting to receive telephone solicitations from these <a
              className="custom-submit__button-as-link"
              href="#"
              onClick={(e) => {
                e.stopPropagation();
                openModal('partnerList');
              }}
            >
              companies
            </a> <span data-tf-element-role="consent-grantor-waived-dnc">even if my telephone number is on a state, federal or corporate Do Not Call list</span>; and agreeing to the <a
              href="#"
              className="custom-submit__button-as-link"
              onClick={(e) => {
                e.stopPropagation();
                openModal('privacyPolicy');
              }}
            >
              Privacy Policy
            </a>, <a
              href="#"
              className="custom-submit__button-as-link"
              onClick={(e) => {
                e.stopPropagation();
                openModal('termsOfUse');
              }}
            >
              Terms of Use
            </a>, and to <a
              href="#"
              className="custom-submit__button-as-link"
              onClick={(e) => {
                e.stopPropagation();
                openModal('arbitrate');
              }}
            >
              Arbitrate
            </a> all disputes, and to sharing of my personal data with third-parties.
            </div>
          </label>
          <div
            style={{
              marginTop: '5px',
              display: 'grid',
              placeContent: 'start',
              marginBottom: '5px',
            }}
          >
            {lenderOptions.map((item, key) => (
              <label className="labelDesign1" key={key} data-tf-element-role={`consent-opted-advertiser-name-${key+1}`}>
                <input
                  data-tf-element-role={`consent-opted-advertiser-input-${key+1}`}
                  id={`leadid_tcpa_brandname_${key + 1}`}
                  type="checkbox"
                  className="checkboxDesign1"
                  checked={selectedList.includes(item.value)}
                  value={item.value}
                  onChange={(e) => {
                    setError(false);
                    onSelect(e);
                  }}
                />
                {item.label}
              </label>
            ))}
          </div>
          <div style={{ fontSize: '13px', marginBottom: '5px', color: 'black' }}>
            <label htmlFor="leadid_tcpa_disclosure_b">
              <input type="hidden" id="leadid_tcpa_disclosure_b" />
              Keep all options selected for the most quotes. I understand my signature and consent is not a condition of
              receiving services and that I may instead email consent@rgrmarketing.com, or to revoke my consent.
            </label>
          </div>
          {error &&
            <div
              style={{ marginBottom: '5px', color: 'red' }}
            >
              You must select a minimum of one partner to get your quote. Selecting all partners is recommended.
            </div>
          }
          <button
            type="button"
            onClick={submitList}
            className="custom-submit__btn"
            data-tf-element-role="submit"
          >
            Get Your Matches
          </button>
        </div>
      </form>
      }
      {lenderOptions.length === 1 &&
        <form data-tf-element-role="offer">
          <div className="form-step__label">You're Almost There!</div>
          <div className="form-step">
            {!firstOneToOneSubmit &&
              <div style={{ fontSize: '13px', marginTop: '10px', color: 'black', marginBottom: '5px' }}>
                We’re sorry, but the partners you selected couldn't assist with your request. Here are some additional
                partners that are ready to assist you!
              </div>
            }
            <label htmlFor="leadid_tcpa_disclosure">
              <div style={{ fontSize: '13px', color: 'black', marginBottom: '5px' }}>
                <input type="hidden" id="leadid_tcpa_disclosure" />
                By clicking "<span data-tf-element-role="submit-text">Get Your Matches</span>" I am electronically signing
                and expressly consenting that <span data-tf-element-role="consent-advertiser-name"><label><input
                type="hidden" />{lenderOptions[0].label}</label></span> may contact me for marketing purposes at <span
                data-tf-element-role="consent-grantor-phone">{phone}</span>, including with an automated telephone
                dialing system, pre-recorded or artificial voice, and/or SMS/MMS, and consenting to receive telephone
                solicitations from these <a
                className="custom-submit__button-as-link"
                href="#"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('partnerList');
                }}
              >
                companies
              </a> <span data-tf-element-role="consent-grantor-waived-dnc"> even if my telephone number is on a state, federal or corporate Do Not Call list</span>;
                and agreeing to the <a
                href="#"
                className="custom-submit__button-as-link"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('privacyPolicy');
                }}
              >
                Privacy Policy
              </a>, <a
                href="#"
                className="custom-submit__button-as-link"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('termsOfUse');
                }}
              >
                Terms of Use
              </a>, and to <a
                href="#"
                className="custom-submit__button-as-link"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('arbitrate');
                }}
              >
                Arbitrate
              </a> all disputes; and to sharing of my personal data with third-parties. I understand my signature and
                consent is not a condition of receiving services and I may instead email consent@rgrmarketing.com to
                receive services, or to revoke my consent.
              </div>
            </label>
            <button type="button" onClick={onSubmit} className="custom-submit__btn" data-tf-element-role="submit">
              Get Your Matches
            </button>
          </div>
        </form>
      }
    </div>
  );
}

export default DesignCheckbox;

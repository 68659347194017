import { useState } from 'react';
import { useDisclaimerContext } from '../../../../lib/contexts';
import { CustomModalContent } from '../../../CustomComponents';

function DesignNoOneToOne({ onSubmit }) {
  const { disclaimerContext } = useDisclaimerContext();
  const { partnerList, termsOfUse, privacyPolicy, arbitrate } =
    disclaimerContext;

  const [isOpen, setIsOpen] = useState({
    partnerList: false,
    termsOfUse: false,
    privacyPolicy: false,
    arbitrate: false,
  });

  const openModal = (key) => setIsOpen({ ...isOpen, [key]: true });
  const closeModal = (key) => setIsOpen({ ...isOpen, [key]: false });

  const click = (event) => {
    if (event.target?.id === 'ppTag') {
      setIsOpen({
        partnerList: false,
        termsOfUse: false,
        privacyPolicy: true,
        arbitrate: false,
      });
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.target?.id === 'touTag') {
      setIsOpen({
        partnerList: false,
        termsOfUse: true,
        privacyPolicy: false,
        arbitrate: false,
      });
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <div className="form-step3">
      <CustomModalContent
        title="Partner List"
        isOpen={isOpen.partnerList}
        onClose={() => closeModal('partnerList')}
        content={partnerList.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Terms Of Use"
        isOpen={isOpen.termsOfUse}
        onClose={() => closeModal('termsOfUse')}
        content={termsOfUse.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Privacy Policy"
        isOpen={isOpen.privacyPolicy}
        onClose={() => closeModal('privacyPolicy')}
        content={privacyPolicy.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Arbitrate"
        isOpen={isOpen.arbitrate}
        onClose={() => closeModal('arbitrate')}
        content={arbitrate.data?.html}
        clickEvent={click}
        isHtml
      />
      <form data-tf-element-role="offer">
        <div className="form-step__label">You're Almost There!</div>
        <div className="form-step">
          <div style={{ fontSize: '13px', marginTop: '5px', color: 'black', marginBottom: '5px' }}>
            <label htmlFor="leadid_tcpa_disclosure">
              <input type="hidden" id="leadid_tcpa_disclosure" />
              By clicking "<span data-tf-element-role="submit-text">Get Your Matches</span>" I am electronically signing
              and expressly consenting that I agree to receive telephone solicitations from these <a
              className="custom-submit__button-as-link" href="#" onClick={(e) => {
              e.stopPropagation();
              openModal('partnerList');
            }}>companies</a> at the number entered <span data-tf-element-role="consent-grantor-waived-dnc">even if my telephone number is on a state, federal or corporate Do Not Call
              list</span>; and agreeing to the <a
              href="#"
              className="custom-submit__button-as-link"
              onClick={(e) => {
                e.stopPropagation();
                openModal('privacyPolicy');
              }}
            >
              Privacy Policy
            </a>, <a
              href="#"
              className="custom-submit__button-as-link"
              onClick={(e) => {
                e.stopPropagation();
                openModal('termsOfUse');
              }}
            >
              Terms of Use
            </a>, and to <a
              href="#"
              className="custom-submit__button-as-link"
              onClick={(e) => {
                e.stopPropagation();
                openModal('arbitrate');
              }}
            >
              Arbitrate
            </a> all disputes, and to sharing of
              my personal data with third-parties. My signature and consent is not a condition of receiving services and
              I may instead email consent@rgrmarketing.com to receive services, or to revoke my consent.
            </label>
              <button type="button" onClick={onSubmit} className="custom-submit__btn" data-tf-element-role="submit">
                Get Your Matches
              </button>
          </div>
    </div>
</form>
    </div>
  );
}

export default DesignNoOneToOne;

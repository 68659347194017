import './MainForm.css';
import './Steps/steps.css';

import {
  DEFAULT_MAIN_SUBTITLE,
  DEFAULT_MAIN_TITLE,
  LOCAL_PHONE_NUMBER,
} from '../../lib/constants';
import { FormProvider, useForm } from 'react-hook-form';
import { Step1, Step2, Step3 } from './Steps';
import {
  getErrorMessage,
  showELocalPhone,
  submitForm,
  submitPostMatchForm,
} from '../../lib/utils';
import {
  useAppContext,
  useFormDataContext,
  useLendersContext,
} from '../../lib/contexts';

import BBBLogo from '../../assets/img/logo_bbb.png';
import { CustomLoader } from '../CustomComponents';
import ReactGA from 'react-ga4';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
// eslint-disable-next-line sort-imports
import { DesignCheckbox } from './Steps/OneToOne';
// eslint-disable-next-line sort-imports
import CustomLoaderLightBox from '../CustomComponents/CustomLoaderLightBox/CustomLoaderLightBox';
import DesignNoOneToOne from './Steps/OneToOne/DesignNoOneToOne';

function MainForm({
  title = DEFAULT_MAIN_TITLE,
  subtitle = DEFAULT_MAIN_SUBTITLE,
}) {
  const navigate = useNavigate();
  const { setLendersContext } = useLendersContext();
  const { formData, clearStorage, updateStep } = useFormDataContext();
  const { appContext } = useAppContext();
  const { step } = formData;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNoText, setIsLoadingNoText] = useState(false);
  const methods = useForm({ defaultValues: formData, mode: 'onSubmit' });
  const { formState } = methods;
  const { errors } = formState;

  const [phone, setPhone] = useState(null);

  const [firstOneToOneSubmit, setFirstOneToOneSubmit] = useState(true);

  const [leadId, setLeadId] = useState(null);
  const [lenderList, setLenderList] = useState(null);
  const [selectedLenders, setSelectedLenders] = useState([]);

  const [noOneToOneDisclaimer, setNoOneToOneDisclaimer] = useState(false);

  const handleSelect = (e) => {
    const { checked, value } = e.target;
    setSelectedLenders(
      checked
        ? [...selectedLenders, value]
        : selectedLenders.filter((item) => item !== value)
    );
  };

  const selectAll = (list) => {
    setSelectedLenders(list);
  };

  const submitPostMatch = async () => {
    setIsLoadingNoText(true);

    let tcpa;

    const tcpaLabel = document.querySelector(
      'label[for="leadid_tcpa_disclosure"]'
    );

    if (tcpaLabel) {
      tcpa = tcpaLabel.textContent;
    } else {
      const tcpaLabelTop = document.querySelector(
        'label[for="leadid_tcpa_disclosure_t"]'
      );

      const tcpaLabelBottom = document.querySelector(
        'label[for="leadid_tcpa_disclosure_b"]'
      );

      if (tcpaLabelTop && tcpaLabelBottom) {
        tcpa = `${tcpaLabelTop.textContent} ${tcpaLabelBottom.textContent}`;
      }
    }

    const result = await submitPostMatchForm({
      lead: leadId,
      brand_consent: selectedLenders,
      tcpa,
    });

    const lendersContextData = {
      name: `${formData.firstname} ${formData.lastname}`,
      zipCode: formData.zip,
    };

    if (result.lenders && result.lenders.length) {
      lendersContextData.lenders = result.lenders;
    }

    if (result.oneToOne && result.oneToOne.length) {
      setLenderList(result.oneToOne);
      updateStep(4);
    }

    if (result.pixel) {
      lendersContextData.pixel = result.pixel;
    }

    if (showELocalPhone(lendersContextData.zipCode, 'US')) {
      lendersContextData.eLocalPhone = LOCAL_PHONE_NUMBER;
    }

    setLendersContext(lendersContextData);

    setFirstOneToOneSubmit(false);
    setNoOneToOneDisclaimer(false);

    if (result.status === 'matched') {
      clearStorage();
      navigate('/thank-you/lender-list');
      setIsLoadingNoText(false);
    } else if (!result.oneToOne || !result.oneToOne.length) {
      clearStorage();
      navigate('/thank-you/alternative');
      setIsLoadingNoText(false);
    }
  };

  const handleGlobalSubmit = async (data) => {
    setIsLoading(true);

    ReactGA.event({
      category: 'GWT Conversion',
      action: 'GWT Conversion',
      label: 'GWT Conversion',
    });

    setPhone(data.phone);
    const result = await submitForm({ ...data, ...appContext });

    const lendersContextData = {
      name: `${formData.firstname} ${formData.lastname}`,
      zipCode: formData.zip,
    };

    if (result.lenders && result.lenders.length) {
      lendersContextData.lenders = result.lenders;
    }

    if (result.noOneToOneDisclaimer) {
      setNoOneToOneDisclaimer(true);
      setLeadId(result.lead_id);
      updateStep(4);
      setIsLoading(false);
    }

    if (result.oneToOne && result.oneToOne.length) {
      setLeadId(result.lead_id);
      setLenderList(result.oneToOne);
      updateStep(4);
    }

    if (result.pixel) {
      lendersContextData.pixel = result.pixel;
    }

    if (showELocalPhone(lendersContextData.zipCode, 'US')) {
      lendersContextData.eLocalPhone = LOCAL_PHONE_NUMBER;
    }

    setLendersContext(lendersContextData);

    if (result.status === 'matched') {
      clearStorage();
      navigate('/thank-you/lender-list');
      setIsLoading(false);
    } else if (
      (!result.oneToOne || !result.oneToOne.length) &&
      !result.noOneToOneDisclaimer
    ) {
      clearStorage();
      navigate('/thank-you/alternative');
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <CustomLoader />}
      {isLoadingNoText && <CustomLoaderLightBox />}
      <div
        style={{ display: isLoading ? 'none' : '' }}
        className="main-form__wrapper"
      >
        <div className="main-form">
          <h2 className="main-form__title">{title}</h2>
          <h3 className="main-form__subtitle">{subtitle}</h3>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleGlobalSubmit)}>
              {!isEmpty(errors) && (
                <div className="form-step__error-box">
                  {getErrorMessage(errors)}
                </div>
              )}
              <div className="main-form__step-container">
                <div className="main-form__step-info">STEP {step} of 4</div>
                {step === 1 && <Step1 />}
                {step === 2 && <Step2 />}
                {step === 3 && <Step3 />}
                {step === 4 && (
                  <>
                    {lenderList && lenderList.length > 0 && (
                      <DesignCheckbox
                        lenderList={lenderList}
                        onSelect={handleSelect}
                        selectedList={selectedLenders}
                        onSubmit={submitPostMatch}
                        phone={phone}
                        firstOneToOneSubmit={firstOneToOneSubmit}
                        selectAll={selectAll}
                        setIsLoading={setIsLoading}
                        setIsLoadingNoText={setIsLoadingNoText}
                      />
                    )}
                    {noOneToOneDisclaimer && (
                      <DesignNoOneToOne onSubmit={submitPostMatch} />
                    )}
                  </>
                )}
                {step === 1 && (
                  <div className="main-form__img-container">
                    <img
                      className="main-form__img"
                      src={BBBLogo}
                      alt="Accredit Business"
                    />
                  </div>
                )}
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
}

export default MainForm;
